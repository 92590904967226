import ChartForm from "../../utilities/chart";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown,faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionExpectedEarning(props){
    const arr = props.data;
        return (
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                <div className="card-header pt-5">
                    <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">Rp. </span>
                            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{formatNumber((arr.expectedEarning != null ? arr.expectedEarning.total : 0))}</span>
                            <span className={(arr.expectedEarning != null ? (arr.expectedEarning.status == 'green' ?
                                "badge badge-light-success fs-base" : "badge badge-light-danger fs-base") : null)}>
                                
                                {/* <FontAwesomeIcon icon={(arr.expectedEarning != null ? (arr.expectedEarning.status == 'green' ?
                                    faArrowUp : faArrowDown) : faMinus)}></FontAwesomeIcon> */}
                                
                                <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>&nbsp;&nbsp;{(arr.expectedEarning != null ? arr.expectedEarning.percent : 0)} %</span>
                        </div>
                        <span className="text-gray-500 pt-1 fw-semibold fs-6">{i18n.t("Expected Earnings")}</span>
                    </div>
                </div>
                <div className="card-body ">
                    <ChartForm
                        dataList = {arr.expectedEarning}
                        type = {'donut'}
                        height = {"350px"}
                    />
                    {/* <div className="d-flex flex-center me-5 pt-2">
                       
                    </div> */}
                    {/* <div className="d-flex flex-column content-justify-center w-100"> */}
                        
                    {/* {
                        arr.expectedEarning != null ?
                            arr.expectedEarning.selling.map((selling, i) => {
                                return (
                                    <div key={'selling'+i} className="d-flex fs-6 fw-semibold align-items-center">
                                        <div className="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                                        <div className="text-gray-500 flex-grow-1 me-4">{selling.item}</div>
                                        <div className="fw-bolder text-gray-700 text-xxl-end">Rp. {formatNumber(parseFloat(selling.qty))}</div>
                                    </div>
                                )
                            })
                            : null
                    } */}
                </div>
                    {/* <div className="d-flex flex-column content-justify-center w-100">
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                            <div className="text-gray-500 flex-grow-1 me-4">Shoes</div>
                            <div className="fw-bolder text-gray-700 text-xxl-end">$7,660</div>
                        </div>
                        <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                            <div className="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                            <div className="text-gray-500 flex-grow-1 me-4">Gaming</div>
                            <div className="fw-bolder text-gray-700 text-xxl-end">$2,820</div>
                        </div>
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{ backgroundColor: "#E4E6EF" }}></div>
                            <div className="text-gray-500 flex-grow-1 me-4">Others</div>
                            <div className="fw-bolder text-gray-700 text-xxl-end">$45,257</div>
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
        );

    }

// export default SectionExpectedEarning;

