import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Home from '../components/home';
// middlewares
import AuthMiddleware from '../reducers/auth/middleware';
import { Navigate } from 'react-router-dom';

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  data:auth.dataDashboard
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signin: credentials => AuthMiddleware.signin(credentials),
    setData: data => AuthMiddleware.dataDashboard(data),
  }, dispatch);
};

class HomeContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func.isRequired,
    dataDashboard: PropTypes.object,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: []
  }

  constructor(props){
    super(props)
    this.refresh=this.refresh.bind(this);
  }

  componentDidMount(){
    this.props.setData();
  }

  refresh(){
    // console.log('masuk')
    this.props.setData();
  }

  render() {
    const { isAuthenticated, isProcessing, isRegistered, isError, errorMessage, signin } = this.props;
    return (
      <Home
        isAuthenticated={isAuthenticated}
        isProcessing={isProcessing}
        isRegistered={isRegistered}
        isError={isError}
        errorMessage={errorMessage}
        signin={signin}
        refresh={this.refresh}
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
