import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import SettlementSalesMiddleware from '../../../../middleware/modules/mutation/settlement-sales';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import SettlementSales from "../../../../components/modules/mutation/settlement-sales";
// middlewares
const mapStateToProps = ({ settlementSales }) => ({
  isProcessing: settlementSales.isProcessing,
  isError: settlementSales.isError,
  errorMessage: settlementSales.errorMessage,
  helperList:settlementSales.helperSettlementSales,
  data: settlementSales.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // getHelper: () => SettlementSalesMiddleware.getHelper(),
      reset: () =>SettlementSalesMiddleware.resetList(),

    },
    dispatch
  );
};
class SettlementSalesContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    // this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Stock Card List")}
          </title>
        </Helmet>
      <SettlementSales
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementSalesContainer);
