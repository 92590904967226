import ChartForm from "../../utilities/chart";
import React from "react";
import i18n from "../../../i18n";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionSalesThisMonth(props){
    const arr = props.data;
    return (
        <div className="card card-flush overflow-hidden h-md-100">
            <div className="card-header py-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-900">{i18n.t("Sales This Months")}</span>
                    {/* // <span className="text-gray-500 mt-1 fw-semibold fs-6">Users from all channels</span> */}
                </h3>
                {/* <div className="card-toolbar">
                    <button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                        <i className="ki-duotone ki-dots-square fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                        </i>
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
                        <div className="menu-item px-3">
                            <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
                        </div>
                        <div className="separator mb-3 opacity-75"></div>
                        <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">New Ticket</a>
                        </div>
                        <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">New Customer</a>
                        </div>
                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                            <a href="#" className="menu-link px-3">
                                <span className="menu-title">New Group</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Admin Group</a>
                                </div>
                                
                                
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Staff Group</a>
                                </div>
                                
                                
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Member Group</a>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        
                        <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">New Contact</a>
                        </div>
                        
                        
                        <div className="separator mt-3 opacity-75"></div>
                        
                        
                        <div className="menu-item px-3">
                            <div className="menu-content px-3 py-3">
                                <a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                </div> */}
                
            </div>
                                        
                                        
            <div className="card-body d-flex  flex-column pb-1 px-0">
                
                <div className="px-9 mb-5">
                    
                    <div className="d-flex mb-2">
                        <span className="fs-4 fw-semibold text-gray-500 me-1">Rp.</span>
                        <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{(arr.salesMonth != null ? formatNumber(arr.salesMonth.total) : 0)}</span>
                    </div>
                    
                </div>
                
                
                {/* <div id="kt_charts_widget_3" className="min-h-auto ps-4 pe-6" style={{height: "300px"}}></div> */}
                <div  className="min-h-auto ps-4 pe-6" style={{height: "300px",minHeight:"315px"}}>

                    <ChartForm 
                        type = {'area'}
                        dataList = {arr.salesMonth}
                        height = {"350px"}
                        />

                </div>
                
            </div>
                                        
        </div>
    );
    // render() {
    //     const {data} = this.props
    //     const arr = data
        
    
    // }
}


