import ChartForm from "../../utilities/chart";
import React from "react";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown,faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionDailySales(props){
    const arr = props.data;
    return (
        <div className="card card-flush h-md-50 mb-5 mb-xl-10">
            <div className="card-header pt-5">
                <div className="card-title d-flex flex-column">
                    <div className="d-flex align-items-center">
                        <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">Rp.</span>
                        <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{(arr.dailySales != null ? formatNumber(arr.dailySales.total) : 0)}</span>
                        <span className={(arr.dailySales != null ? (arr.dailySales.status == 'green' ?
                            "badge badge-light-success fs-base" : "badge badge-light-danger fs-base") : null)}>
                        <FontAwesomeIcon icon={(arr.dailySales != null ? (arr.dailySales.status == 'green' ?
                            faArrowUp : faArrowDown) : faMinus)}></FontAwesomeIcon>
                        <i className="fa-arrow ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1">   </span>
                            <span className="path2">   </span>
                        </i>&nbsp;&nbsp;{(arr.dailySales != null ? arr.dailySales.percent : 0)} %</span>
                    </div>
                    <span className="text-gray-500 pt-1 fw-semibold fs-6">{i18n.t("Daily Sales")}</span>
                </div>
            </div>

            {/* <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{(arr.orderThisWeek != null ? arr.orderThisWeek.selling : 0)}</span>

                            <span className={(arr.orderThisWeek != null ? (arr.orderThisWeek.status == 'green' ?
                                "badge badge-light-success fs-base" : "badge badge-light-danger fs-base") : null)}>
                                
                                <FontAwesomeIcon icon={(arr.orderThisWeek != null ? (arr.orderThisWeek.status == 'green' ?
                                    faArrowUp : faArrowDown) : faMinus)}></FontAwesomeIcon>
                                
                                <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>&nbsp;&nbsp;{(arr.orderThisWeek != null ? arr.orderThisWeek.percent : 0)} %</span>
                        </div>
                        <span className="text-gray-500 pt-1 fw-semibold fs-6">{i18n.t("Orders This Week")}</span>
                    </div> */}


            <div className="mr-5">
                <ChartForm 
                    type = {'bar'}
                    dataList = {arr.dailySales}
                />
                {/* <div id="kt_card_widget_6_chart" className="w-100" style={{height: "80px"}}></div> */}
            </div>
        </div>
    );
    
}
// }

// export default SectionDailySales;

