import React, { Component, Fragment } from "react";
import TableStockCard from "./table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import TableSettlementSales from "./table";
import FormSettlementSales from "./form";
import { Button } from "react-bootstrap";

const MySwal = withReactContent(Swal);
export class DataSettlementSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      button:false,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      columns: [
        {
          key: "transaction_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. Transaction"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Branch"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "name_sales",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Name Sales"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "name_customer",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Name Customer"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "payment_type",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Payment Type"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "total_format",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Total"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
       
      ],
    };
  }

  renderTable() {
    console.log(this.state.listData);
    
    if(this.state.listData != null && this.state.listData != [] && this.state.listData != undefined){
      let data = this.state.listData.data;

      if(data != null && data != [] && data != undefined){
        return(
        // return this.state.listData.map((data, i) => (
          <Fragment >
            <TableSettlementSales 
              {...this.props} 
              checkAble={true}
              data={this.state.listData}
              columns={this.state.columns} 
              expandAble={true}
              theExpand={{
                key: "selling_detail",
                columns: [
                  { label: i18n.t("Name Item"), key: "name_item" },
                  { label: i18n.t("Unit"), key: "name_unit" },
                  { label: i18n.t("Qty"), key: "qty_format" },
                  { label: i18n.t("Nett Price"), key: "nett_price_format" },
                  { label: i18n.t("Total"), key: "total_format" },
                  
                ],
              }}
              >

            </TableSettlementSales>
          </Fragment>
        );
      }
    }
  }

  saveSettlement(id) {
    const html = (
      <>
        <div>{i18n.t("Are you sure to save this Settlement")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            // this.props.deleteItem(id, this.state.search);
            // MySwal.update({
            //   title: i18n.t("Deleting Data"),
            //   html: i18n.t("Please Wait ...."),
            //   icon: "info",
            // });
            // MySwal.showLoading();
          }}
        >
          {i18n.t("Save")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  
  render() {
    const { isProcessing, data } = this.props;
    if(data != null && data != undefined){
      this.state.listData = data;
    }
    console.log('PROPS',this.props)

    return (
      <div id="invoice-page">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
                  <FormSettlementSales
                    {...this.props}
                    data={data}
                  />
                 {isProcessing ?<div className="text-center">
                    {/* <div className="table-loading-message">   */}
                      <div className="spinner-border  text-primary text-center  clearfix"></div>
                      <div className="text-center">{i18n.t("Loading")} </div>
                    {/* </div> */}
                
                  </div>:null}
              </div>
             
            </div>
            { data != null && data != undefined? this.renderTable() : ""}
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" style={{paddingRight:'10px'}}>
            { data.data != null && data.data != []?
              <Fragment>
                <Button
                  type="button"
                  className="btn btn-primary mr-10"
                  onClick={(e) => this.saveSettlement()}
                >
                  {i18n.t("Settlement")}
              </Button>
            </Fragment>:""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataSettlementSales;
