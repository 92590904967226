import React, { Component, Fragment, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown } from "react-bootstrap";
import FormUpdateContainer from "../../../../containers/modules/sales/visit-schedule/formUpdate"
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faEye, faPencil, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { StatusCheckIn } from "../../../utilities/Const/StatusActive";
import LabelSchedule from "../../../utilities/Status/StatusSchedule";
import FormChangeContainer from "../../../../containers/modules/sales/visit-schedule/formChange";
const MySwal = withReactContent(Swal);
export class ListVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Visit Schedule"), class: " text-gray-600" },
        { link: null, title: i18n.t("List Schedule"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "code",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Code Customer"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "simpleName",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Customer"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "code_branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "code_employee",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Code Sales"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "name_employee",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Sales"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "district",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("District"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "subDistrict",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Sub District"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:false
        },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:true
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: StatusCheckIn,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component:(data,column)=>{
                return <LabelSchedule value={data[column.key]} />
          }
        },
       
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "visit",
          filter: {
            active: false,
          },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-70px",
          tdClass: "text-center",
          component:(data)=>{
            return (
            <Fragment>
              {data.status==0 && 
              <Button className="btn btn-sm btn-warning" onClick={()=>this.updateData(data.id)}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></Button>
              // <Dropdown id="dropdown-btnaction">
              //   <Dropdown.Toggle
              //     variant="none"
              //     className="btn btn-sm btn-light btn-active-light-primary"
              //     id="dropdown-basic"
              //   >
              //     {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
              //   </Dropdown.Toggle>
              //   <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
              //     {/* Edit Button */}
              //     <div className="menu-item px-3">
              //           <Dropdown.Item
              //             href="#"
              //             className="menu-link px-3"
              //             onClick={() => this.updateData(data.id)}
              //           >
              //             {i18n.t("Edit")}
              //           </Dropdown.Item>
              //         </div>
                      
              //   </Dropdown.Menu>
              // </Dropdown>
              }
              {(data.status==3 || data.status==4) &&
                <Button className="btn btn-sm btn-success" onClick={()=>this.viewInfo(data)}><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button>
              }
            </Fragment>)
          }
        },
      ],
      titleModal:""
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }

  
  componentWillMount() {

    this.props.reset();
    this.props.search(this.state.search);
  
  }
  viewInfo(data){
    var text=""
    switch(data.status){
      case 3:
        text="Change Schedule"
        break;
      case 4:
        text="Change Sales"
        break;
    }
    MySwal.fire({
      title: i18n.t(text),
      html: data.remark,
      icon: "info",
      showConfirmButton: true,
    });
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }


  updateData(id) {
    this.state.titleModal = i18n.t("Edit Visit Schedule");
    this.props.update(id);
  }
  changeData() {
    this.state.titleModal = i18n.t("Change Schedule");
    this.props.change();
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t('You will delete this Item')}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal, showModalChange  } = this.props;
    console.log(data)
    return (
      <div id="List-Schedule">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={(e) => this.changeData()}
                    >
                      {i18n.t("Change Schedule")}
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  data={data}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  changeData={this.changeData}
                  isProcessing={isProcessing}
                  defaultSort={{name:'asc'}}
                />
              </div>
            </div>
          </div>
        </div>
        <FormUpdateContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
        <FormChangeContainer
          showModalChange={showModalChange}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default ListVisit;
