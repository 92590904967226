import React, { Component, Fragment } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/master/config-branch/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import LabelActive from "../../../utilities/Status/active";
import { StatusActive } from "../../../utilities/Const/StatusActive";
import i18n from "../../../../i18n";
const MySwal = withReactContent(Swal);
export class ConfigBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Master"), class: " text-gray-600" },
        { link: null, title: i18n.t("Config Branch"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "code",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Code"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "action",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          form:'icon',
          buttons:[
            {
              key: "id",
              btnText: i18n.t("Edit"),
              visible: {
                key: "status",
                params: [],
                function: (data, column) => {
                  const {authUser}=this.props
                  if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
                    let route=authUser.userAccess.route
                      if(route.includes('Config Branch Show')){
                        return true
                      }
                  }
                  return false;
                },
              },
              btnFunction: (data, column) => {
                this.updateData(data);
              },
              bthClass: "btn btn-warning",
            },
          ],
          // component: (data, column) => {
          //   const {authUser}=this.props
          //   let route=[];
          //   console.log('datata', data)
          //   if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
          //     route=authUser.userAccess.route
          //   }
          //   return (
          //     <Fragment>
          //       {(data.status !=10)?
          //       <Dropdown id="dropdown-btnaction">
          //       <Dropdown.Toggle
          //         variant="none"
          //         className="btn btn-sm btn-light btn-active-light-primary"
          //         id="dropdown-basic"
          //       >
          //         {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
          //       </Dropdown.Toggle>
          //       <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
          //         {/* Edit Button */}
          //           <>
          //             {(route.includes('Config Branch Show'))?
          //               <div className="menu-item px-3">
          //               <Dropdown.Item
          //                 href="#"
          //                 className="menu-link px-3"
          //                 onClick={() => this.updateData(data.id)}
          //               >
          //                 {i18n.t("Edit")}
          //               </Dropdown.Item>
          //             </div>:null}
          //             </>
          //       </Dropdown.Menu>
          //     </Dropdown>:null
          //       }
          //     </Fragment>
          //   );
          // },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Config Branch");
    this.props.createData();
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Config Branch");
    this.props.updateItem(id);
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You will delete this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }

  renderCreateButton(){
    const {authUser}=this.props
    if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
      let route=authUser.userAccess.route
      if(route.includes('Config Branch Create')){
        return (<Button
          type="button"
          className="btn btn-primary m-1"
          onClick={(e) => this.createData()}
        >
          {i18n.t("Add Config Branch")}
        </Button>)
      }
    }
    return null;
  }

  render() {
    const { isProcessing, data, showModalConfig } = this.props;
    this.state.listData = data;
    return (
      <div id="config-branch">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Fragment>
                    {
                      this.renderCreateButton()
                    }
                    </Fragment>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModalConfig={showModalConfig}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default ConfigBranch;
