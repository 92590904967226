import ChartForm from "../../utilities/chart";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown,faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionOrderWeek(props){
    const arr = props.data;
    let percent = '';
    if(arr.orderThisWeek != null){
        percent = arr.orderThisWeek != null ? arr.orderThisWeek.percentWeek + '%' : 0 + '%'
    }
        console.log(percent)
        return (
            <div className="card card-flush h-md-50 mb-xl-10">
                <div className="card-header pt-5">
                    <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{(arr.orderThisWeek != null ? formatNumber(arr.orderThisWeek.selling) : 0)}</span>

                            <span className={(arr.orderThisWeek != null ? (arr.orderThisWeek.status == 'green' ?
                                "badge badge-light-success fs-base" : "badge badge-light-danger fs-base") : null)}>
                                
                                <FontAwesomeIcon icon={(arr.orderThisWeek != null ? (arr.orderThisWeek.status == 'green' ?
                                    faArrowUp : faArrowDown) : faMinus)}></FontAwesomeIcon>
                                
                                <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>&nbsp;&nbsp;{(arr.orderThisWeek != null ? arr.orderThisWeek.percent : 0)} %</span>
                        </div>
                        <span className="text-gray-500 pt-1 fw-semibold fs-6">{i18n.t("Orders This Week")}</span>
                    </div>
                </div>
                

                
                {/* <div className="card-body d-flex align-items-end pt-0">
                    <div className="d-flex align-items-center flex-column mt-3 w-100">
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span className="fw-bolder fs-6 text-gray-900">1,048 to Goal</span>
                            <span className="fw-bold fs-6 text-gray-500">62%</span>
                        </div>
                        <div className="h-8px mx-3 w-100 bg-light-success rounded">
                            <div className="bg-success rounded h-8px" role="progressbar" style={{ width: "62%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div> */}
                <div className="card-body d-flex align-items-end pt-0">
                    <div className="d-flex align-items-center flex-column mt-3 w-100">
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span className="fw-bolder fs-6 text-gray-900">{(arr.orderThisWeek != null ? formatNumber(arr.orderThisWeek.total) : 0)} {i18n.t(" to Goal")}</span>
                            <span className="fw-bold fs-6 text-gray-500">{arr.orderThisWeek != null ? arr.orderThisWeek.percentWeek : 0} %</span>
                        </div>
                        <div className="h-8px mx-3 w-100 bg-light-success rounded">
                            <div className="bg-success rounded h-8px" role="progressbar"
                             style={{ width: percent}}
                             aria-valuenow={arr.orderThisWeek != null ? arr.orderThisWeek.selling : 0} 
                             aria-valuemin="0" 
                             aria-valuemax={(arr.orderThisWeek != null ? arr.orderThisWeek.totalTarget : 0)}></div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

// export default SectionExpectedEarning;

