import ChartForm from "../../utilities/chart";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown,faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionCustomerSales(props){
    const arr = props.data;
    let percent = '';
    if(arr.orderThisWeek != null){
        percent = arr.orderThisWeek != null ? arr.orderThisWeek.percentWeek + '%' : 0 + '%'
    }
    return(
    <div className="card card-flush h-md-50 mb-xl-10">
        <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
                <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{(arr.newCustomer != null ? arr.newCustomer.total : 0)}</span>
                <span className="text-gray-500 pt-1 fw-semibold fs-6">{i18n.t("New Customers This Month")}</span>
            </div>
        </div>
        <div className="card-body d-flex flex-column justify-content-end pe-0">
            <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">{i18n.t("Active Sales")}</span>
            <div key={"masterSales"} className="symbol-group symbol-hover flex-nowrap">
                {
                    arr.newCustomer != null ?
                        arr.newCustomer.sales.map((sales, i) => {
                            return (
                                <div key={"sales" + i} className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title={sales.name}>
                                    <span className={i % 2 == 0 ?
                                        "symbol-label bg-warning text-inverse-warning fw-bold" : "symbol-label bg-primary text-inverse-primary fw-bold"}>{sales.initial}</span>
                                </div>
                            )
                        })
                        : null
                }
                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                    <span className="symbol-label bg-light text-black-400 fs-8 fw-bold">+{arr.newCustomer != null ? arr.newCustomer.salesCount : 0}</span>
                </div>
            </div>
        </div>
    </div>
    );

}

// export default SectionExpectedEarning;

